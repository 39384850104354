import React from 'react'
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
// import Layout from '../components/layout'
import SEO from '../components/seo'

const Operation = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'pages.operation.title' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <form>
        <div className='interfaceRow justifyCenter'>
          <Link to='/manual-operation/'>
            <FormattedMessage id='pages.operation.manualoperation' />
          </Link>
        </div>
      </form>
      <div className='interfaceRow justifyCenter'>
        <Link to='/scheduler/'>
          <FormattedMessage id='pages.operation.scheduler' />
        </Link>
      </div>
    </div>
  </>
)

export default injectIntl(Operation)
